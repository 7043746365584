import React, { useLayoutEffect } from 'react';
import CMS from 'netlify-cms-app';
import styled from 'styled-components';
import typefaceInter from '!css-loader!fontsource-inter';

import GlobalStyle from '../styles/global-styles';
import CMSStyle from '../styles/cms-styles';
import StyleInjector from '../components/StyleInjector';
import Nav from '../components/shared/Nav';
import Album from '../components/Album';
import LiveRecording from '../components/content_blocks/LiveRecording';
import LiveVideo from '../components/content_blocks/LiveVideo';
import Photo from '../components/content_blocks/Photo';
import Polaroid from '../components/content_blocks/Polaroid';
import Poster from '../components/content_blocks/Poster';
import Text from '../components/content_blocks/Text';

import config from '../../public/admin/config.yml';
import ShareEmbed from '../components/ShareEmbed';
import HighlightedItem from '../components/highlights/HighlightedItem';

const PreviewComponentWrapper: React.FC = function (props) {
  const { children } = props;

  return (
    <StyleInjector>
      <>
        <GlobalStyle />
        <CMSStyle />
        {children}
      </>
    </StyleInjector>
  );
};

const StyledWrapper = styled.div`
  display: inline-block;
  padding: 2rem;
`;

const NetlifyCMSPage: React.FC = function () {
  useLayoutEffect(() => {
    CMS.init({ config });

    CMS.registerPreviewStyle(typefaceInter, { raw: true });

    CMS.registerPreviewTemplate('site_configuration', ({ entry }) => (
      <PreviewComponentWrapper>
        <Nav menuItems={entry.getIn(['data', 'menu_items']).toJS()} />
      </PreviewComponentWrapper>
    ));

    CMS.registerPreviewTemplate(
      'highlighted',
      ({ entry, getAsset, fieldsMetaData }) => {
        const highlightedItem = fieldsMetaData
          .getIn([
            'highlighted_item',
            'live_video',
            entry.getIn(['data', 'highlighted_item']),
          ])
          ?.toJS();
        return (
          <PreviewComponentWrapper>
            {highlightedItem && (
              <HighlightedItem
                content={highlightedItem}
                imgSrc={getAsset(highlightedItem.main_image)}
                preview={true}
              />
            )}
          </PreviewComponentWrapper>
        );
      }
    );

    CMS.registerPreviewTemplate('share', ({ entry }) => (
      <PreviewComponentWrapper>
        <ShareEmbed url={entry.getIn(['data', 'url'])} />
      </PreviewComponentWrapper>
    ));

    CMS.registerPreviewTemplate('album', ({ entry, getAsset }) => (
      <PreviewComponentWrapper>
        <StyledWrapper>
          <Album
            content={entry.getIn(['data']).toJS()}
            imgSrc={getAsset(entry.getIn(['data', 'main_image']))}
            preview={true}
          />
        </StyledWrapper>
      </PreviewComponentWrapper>
    ));

    CMS.registerPreviewTemplate('live_recording', ({ entry, getAsset }) => (
      <PreviewComponentWrapper>
        <GlobalStyle />
        <CMSStyle />
        <StyledWrapper>
          <LiveRecording
            content={entry.getIn(['data']).toJS()}
            imgSrc={getAsset(entry.getIn(['data', 'main_image']))}
            preview={true}
          />
        </StyledWrapper>
      </PreviewComponentWrapper>
    ));

    CMS.registerPreviewTemplate('live_video', ({ entry, getAsset }) => (
      <PreviewComponentWrapper>
        <StyledWrapper>
          <LiveVideo
            content={entry.getIn(['data']).toJS()}
            imgSrc={getAsset(entry.getIn(['data', 'main_image']))}
            preview={true}
          />
        </StyledWrapper>
      </PreviewComponentWrapper>
    ));

    CMS.registerPreviewTemplate('photo', ({ entry, getAsset }) => (
      <PreviewComponentWrapper>
        <StyledWrapper>
          <Photo
            content={entry.getIn(['data']).toJS()}
            imgSrc={getAsset(entry.getIn(['data', 'main_image']))}
            preview={true}
          />
        </StyledWrapper>
      </PreviewComponentWrapper>
    ));

    CMS.registerPreviewTemplate('polaroid', ({ entry, getAsset }) => (
      <PreviewComponentWrapper>
        <StyledWrapper>
          <Polaroid
            content={entry.getIn(['data']).toJS()}
            imgSrc={getAsset(entry.getIn(['data', 'main_image']))}
            preview={true}
          />
        </StyledWrapper>
      </PreviewComponentWrapper>
    ));

    CMS.registerPreviewTemplate('poster', ({ entry, getAsset }) => (
      <PreviewComponentWrapper>
        <StyledWrapper>
          <Poster
            content={entry.getIn(['data']).toJS()}
            imgSrc={getAsset(entry.getIn(['data', 'main_image']))}
            preview={true}
          />
        </StyledWrapper>
      </PreviewComponentWrapper>
    ));

    CMS.registerPreviewTemplate('text', ({ entry, fieldsMetaData }) => {
      const showInfo = fieldsMetaData
        .getIn(['show', 'show', entry.getIn(['data', 'show'])])
        ?.toJS();
      return (
        <PreviewComponentWrapper>
          <StyledWrapper>
            {showInfo && (
              <Text
                content={entry.getIn(['data']).toJS()}
                preview={true}
                previewShow={showInfo}
              />
            )}
          </StyledWrapper>
        </PreviewComponentWrapper>
      );
    });
  }, []);

  return <div />;
};

export default NetlifyCMSPage;
