import React, { useState, useEffect } from 'react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import theme from '../styles/theme';

const StyleInjector: React.FC = function ({ children }) {
  const [iframeRef, setIframeRef] = useState(null);

  useEffect(() => {
    const iframe = document.getElementsByTagName('iframe')[0];
    const iframeHeadElem = iframe.contentDocument.head;
    setIframeRef(iframeHeadElem);
  }, []);

  return (
    iframeRef && (
      <StyleSheetManager target={iframeRef}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </StyleSheetManager>
    )
  );
};

export default StyleInjector;
