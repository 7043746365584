import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import borderUrl from '../../assets/images/tv_border.svg';
import titleUrl from '../../assets/images/highlighted_title.svg';
import { ReactComponent as BigPlayButton } from '../../assets/images/tv_play_button_big.svg';
import { ReactComponent as BigPlayArrow } from '../../assets/images/tv_play_arrow_big.svg';
import PreviewImg from '../shared/PreviewImg';

const StyledContainer = styled.div`
  display: inline-block;
  text-align: center;
`;

const StyledTitleBorder = styled.div`
  display: inline-block;
  margin-bottom: 2rem;
  ${props => props.theme.borderMask(titleUrl)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    margin-bottom: 1rem;
  }
`;

const StyledTitle = styled.div`
  padding: 0.6rem 1rem 0.3rem;
  background: ${props => props.theme.white};
  ${props => props.theme.customTextStyle};
  ${props => props.theme.mask(titleUrl)};
`;

const StyledBorder = styled.div`
  position: relative;
  ${props => props.theme.borderMask(borderUrl)};
`;

const StyledScreen = styled.div`
  position: relative;
  width: 100%;
  height: calc(9 / 16 * 70vw);
  background: ${props => props.theme.white};
  ${props => props.theme.mask(borderUrl)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    height: 60vh;
  }
`;

const StyledPlayer = styled.iframe`
  position: absolute;
  top: -1%;
  left: -1%;
  height: 102%;
  width: 102%;
`;

const StyledPreviewImg = styled(PreviewImg)`
  width: 100%;
  height: 100%;
`;

const StyledThumbnail = styled(Img)`
  width: 100%;
  height: 100%;
`;

const StyledBigPlayButton = styled(BigPlayButton)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 3.5rem;
  fill: ${props => props.theme.white};
  stroke: ${props => props.theme.black};
  stroke-width: 2.5;
`;

const StyledBigPlayArrow = styled(BigPlayArrow)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 1.5rem;
  fill: ${props => props.theme.white};
  stroke: ${props => props.theme.black};
  stroke-width: 2.2;
`;

const StyledLoading = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.875rem;
  ${props => props.theme.customTextStyle};
`;

interface HighlightedItemProps {
  content: Collections.LiveVideo;
  imgSrc?: { url: string; path: string; field?: any; fileObj: File };
  preview?: boolean;
}

const HighlightedItem: React.FC<HighlightedItemProps> = props => {
  const {
    content: { title, main_image, video_id },
    imgSrc,
    preview,
  } = props;

  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(true);

  const container = useRef(null);
  const iframe = useRef(null);

  useEffect(() => {
    iframe?.current?.addEventListener('load', iframeOnLoad);
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => {
      container?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }, 500);
  }, []);

  const iframeOnLoad = () => {
    setLoading(false);
  };

  const handlePlayClick = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <StyledContainer ref={container}>
      <StyledTitleBorder>
        <StyledTitle>{title}</StyledTitle>
      </StyledTitleBorder>
      <StyledBorder onClick={handlePlayClick}>
        <StyledScreen>
          {isPlaying ? (
            <>
              {loading && <StyledLoading>Loading...</StyledLoading>}
              <StyledPlayer
                ref={iframe}
                src={`https://www.youtube.com/embed/${video_id}?autoplay=1`}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </>
          ) : (
            <>
              {preview ? (
                <StyledPreviewImg src={imgSrc} />
              ) : (
                <StyledThumbnail
                  fluid={{ ...main_image?.childImageSharp.fluid }}
                />
              )}
              <StyledBigPlayButton />
              <StyledBigPlayArrow />
            </>
          )}
        </StyledScreen>
      </StyledBorder>
    </StyledContainer>
  );
};

export default HighlightedItem;
