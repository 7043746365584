import React from 'react';
import styled from 'styled-components';

import borderUrl from '../assets/images/disco_embed_border.svg';

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 50px);
  margin-bottom: 50px;

  @media (max-width: ${props => props.theme.breakMedium}) {
    margin: 50px 0 0;
  }

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

const StyledBorder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  ${props => props.theme.borderMask(borderUrl)};

  @media (max-width: ${props => props.theme.breakSmall}) {
    width: 90%;
    height: 90%;
  }
`;

const StyledMask = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: ${props => props.theme.white};
  ${props => props.theme.mask(borderUrl)};

  iframe {
    position: absolute;
    top: -1%;
    left: -1%;
    height: 102%;
    width: 102%;
  }
`;

interface ShareEmbedProps {
  url: string;
}

const ShareEmbed: React.FC<ShareEmbedProps> = props => {
  const { url } = props;

  return (
    <StyledContainer>
      <StyledBorder>
        <StyledMask>
          <iframe src={url} />
        </StyledMask>
      </StyledBorder>
    </StyledContainer>
  );
};

export default ShareEmbed;
